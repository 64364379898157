import {Home as Homeicon, User} from 'react-feather'
import {useState} from 'react'
import {Input, Label} from 'reactstrap'

const Home = () =>

{

    const [vo, setvo] = useState(null);

    return <><h1 style={{color:"red"}}>
<Label>Digite o nome da sua avó</Label>
        <Input onChange={(e) => setvo(e.target.value)} value={vo} />
        <User size={18} /> Joao Gabriel </h1>

        {vo === "Ana Lucia" && <h3>O nome da mãe da minha mãe é: {vo}</h3> }
        {vo === "Mirian" && <h3>O nome da mãe do pai  é: {vo}</h3> }
        {vo === "Ana Lucia" && <h3> O nome  da mae da minha tia e: {vo}  </h3> }
        {vo === "Dirceu" && <h3> O nome do meu pai e: {vo}</h3> }
        {vo === "Thays" && <h3> O nome da minha mae e: {vo}</h3> }







    </>
}

export default Home
